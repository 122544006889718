// @flow
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { media, mediumHeading } from "@nested/brand";

const wrapperStyle = css`
  background-color: ${({ theme }) => theme.palette.hague};
`;

const contentStyle = css`
  padding: 40px 20px;
  color: white;

  ${media.tablet`
    padding: 80px 20px;
    max-width: 745px;
    margin: auto;
  `}
`;

const headingStyle = css`
  ${mediumHeading};
  margin: 0;
  color: white;
`;

const tableWrapper = css`
  margin-top: 15px;
  ${media.tablet`
    margin-top: 40px;
  `}
`;

const jobWrapper = css`
  display: block;
  text-decoration: none;
  padding: 15px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  line-height: 24px;
  font-size: 18px;
  position: relative;
  &:last-child {
    border-bottom: none;
    padding-bottom: none;
  }

  ${media.tablet`
    display: flex;
    flex-direction: row;
    padding: 20px 0;
    align-items: center;
  `}
`;

const jobTitleStyle = css`
  font-weight: 500;
  ${media.tablet`
    width: 50%;
    padding-right: 20px;
    box-sizing: border-box;
  `}
`;

const departmentStyle = css`
  display: inline-block;
  ${media.tablet`
    width: 25%;
    padding-right: 20px;
  `}
`;

const cityStyle = css`
  display: inline-block;
  margin-left: 20px;
  ${media.tablet`
    margin-left: 0;
    width: 25%;
  `}
`;

const arrowStyle = css`
  position: absolute;
  top: 50%;
  right: 0;
  color: #ffcfc6;
  ${media.tablet`
    transform: translateY(-50%);
  `}
`;

const getInTouchBoxStyle = css`
  margin-top: 15px;
  background-color: #052938;
  border-radius: 10px;
  padding: 15px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  ${media.tablet`
    margin-top: 40px;
    padding: 20px;
  `}
`;

const headingsStyle = css`
  display: none;

  ${media.tablet`
    font-size: 18px;
    line-height: 30px;
    opacity: 0.7;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    font-weight: 500;
    padding-bottom: 25px;
    display: flex;
    flex-direction: row;
  `}
`;

const getInTouchTextStyle = css`
  white-space: pre-wrap;
  ${media.tablet`
    flex-grow: 1;
    padding-right: 20px;
  `}
`;

const contactEmailStyles = css`
  font-size: 16px;
  text-decoration: none;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
  }
`;

type Props = {|
  contactEmail: string,
  heading: string,
  bottomText: string,
  id?: string,
  jobs: {
    title: string,
    department: string,
    city: string,
    url: string,
  }[],
|};

export const JobList = ({
  heading,
  jobs,
  contactEmail,
  bottomText,
  id,
}: Props) => {
  return (
    <section css={wrapperStyle} id={id}>
      <div css={contentStyle}>
        <h3 css={headingStyle}>{heading}</h3>
        <div css={tableWrapper}>
          <div css={headingsStyle}>
            <div css={jobTitleStyle}>Job title</div>
            <div css={departmentStyle}>Department</div>
            <div css={cityStyle}>Location</div>
          </div>
          {jobs.map((job) => (
            <a href={job.url} target="_blank" css={jobWrapper}>
              <div css={jobTitleStyle}>{job.title}</div>
              <div css={departmentStyle}>{job.department}</div>
              <div css={cityStyle}>{job.city}</div>
              <div css={arrowStyle}>
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </div>
            </a>
          ))}
        </div>
        <div css={getInTouchBoxStyle}>
          <div css={getInTouchTextStyle}>{bottomText}</div>
          <a css={contactEmailStyles} href={`mailto:${contactEmail}`}>
            {contactEmail}
          </a>
        </div>
      </div>
    </section>
  );
};

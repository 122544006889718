import { useEffect } from "react";
import { Sentry } from "@nested/isomorphic-sentry";
import { useWorkableJobs } from "./useWorkableJobs";
import { JobList } from "./JobList";

export const CurrentJobs = ({ repeat, ...rest }) => {
  const departments = repeat.map(({ department }) => department);

  const { error, loading, jobs } = useWorkableJobs(departments);

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  // Better to show nothing than an empty list of jobs
  if (!loading && jobs.length === 0) {
    return null;
  }

  return <JobList jobs={jobs} {...rest} id="current-jobs-list" />;
};

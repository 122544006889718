import { useState, useEffect } from "react";

export const useWorkableJobs = (departments) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const run = async () => {
      try {
        const response = await fetch(
          "https://apply.workable.com/api/v1/widget/accounts/nested",
        );

        const json = await response.json();

        const filteredJobs = json.jobs.filter((job) =>
          departments.includes(job.department),
        );

        setJobs(filteredJobs);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    run();
  }, []);

  return {
    jobs,
    loading,
    error,
  };
};
